import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

const Tool = () => {

    const history  = useHistory();

    useEffect(() => {
        document.title = "How to use socks5 - " + window?.title;
    }, []);

    return (
        <div className="container">
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content p-5">
                            <h2 className="text-center mb-5">HOW TO USE SOCKS5</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Maunual Video with Firefox</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/J5MfcOGcGbM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-md-6">
                                    <h3>Maunual Video with Chrome</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/FL4xT1kautg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Maunual Video with Opera</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/M1zK7sy3fHI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-md-6">
                                    <h3>Maunual Video with Proxifier</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/yuUL-KMq1kQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <h3 className="mt-3">Contact Us:</h3>
                            <ul>
                                <li><strong>Email:</strong> {window.confirm?.support_email}</li>
                                <li><strong>Skype:</strong> {window.confirm?.skype}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tool;