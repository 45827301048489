import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';

const Success = () => {

    const history = useHistory();

    useEffect(() => {
        document.title = "Success - " + window?.title;
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="mt-5 pt-5 pb-5 success-box" style={{maxWidth: "640px", background: "#fff", margin: "0 auto", width: "100%"}}>
                        <CheckCircleOutlined style={{color: "#99d834"}} />
                        <p style={{fontSize: "28px", fontWeight: "bold", marginBottom: "0px"}}>Success</p>
                        <p style={{fontSize: "16px"}}>Your payment has been processed successfully.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Success;