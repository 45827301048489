import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { account } from '../../endpoint';

const Forgot = () => {
    const history  = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));

    const onFinish = async (values) => {
        //console.log('Success:', values);
        const hide = message.loading('Processing...');
        try {
            var res = await account.forgot(values);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: res.message,
                    type: 'success'
                });
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Forgot failed, please try again!',
                type: 'error'
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        document.title = "Forgot - " + window?.title;
        if (user != null) {
            history.push({pathname: '/home'});
        }
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-3"></div>
                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-custom">
                        <div className="panel-heading">USER FORGOT PASSWORD</div>
                        <div className="panel-body">
                            <div id="cards">
                                <div className="section_content">
                                    <Form
                                        name="basic"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        className="form-horizontal mt-3"
                                        id="forgotform"
                                    >
                                        <Form.Item
                                            label="Email:"
                                            labelCol={{span: 24}}
                                            name="email"
                                            rules={[{ required: true, type: "email", message: 'Please input your email!' }]}
                                        >
                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                        </Form.Item>
                                        <div className="mb-3 text-center">
                                            <div className="col-xs-12 pb-3">
                                                <Button type="primary" htmlType="submit" className="d-block medium">Submit</Button>
                                                <div className="h-10"></div>
                                                <Link to="/">Cancel</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-3"></div>
            </div>
        </div>
    );
}

export default Forgot;