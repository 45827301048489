import { Request } from '../_until';
import { notification } from 'antd';

export const users = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeStatus,
    deleteItems,
    resetItem,
    getSelect,
    changeStatusApi,
    updatePlanItem,
    updateMaxDevice,
    updateMaxConfirm,
    updateCurrentConfirm,
    updateAutoConfirm
};

const urls = {
    get_list: `/users`,
    get_item: `/users/get/`,
    get_select: `/users/get_select`,
    add_item: `/users/add`,
    edit_item: `/users/update`,
    change_status: `/users/change_status/`,
    delete_item: `/users/delete`,
    reset_item: `/users/reset/`,
    update_plan_item: `/users/update_plan/`,
    change_api_status: `/users/change_api_status/`,
    change_max_device: `/users/change_device/`,
    change_current_confirm : `/users/change_current_confirm/`,
    change_auto_confirm : `/users/change_auto_confirm/`,
    change_max_confirm : `/users/change_max_confirm/`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function changeStatusApi(id, requestOptions) {
    return await Request.post(urls.change_api_status + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function resetItem(id, requestOptions) {
    return await Request.post(urls.reset_item + id, requestOptions).then(res => res.data);
};

async function updatePlanItem(id, requestOptions) {
    return await Request.post(urls.update_plan_item + id, requestOptions).then(res => res.data);
};

async function updateMaxDevice(id, requestOptions) {
    return await Request.post(urls.change_max_device + id, requestOptions).then(res => res.data);
};

async function updateCurrentConfirm(id, requestOptions) {
    return await Request.post(urls.change_current_confirm + id, requestOptions).then(res => res.data);
};

async function updateMaxConfirm(id, requestOptions) {
    return await Request.post(urls.change_max_confirm + id, requestOptions).then(res => res.data);
};

async function updateAutoConfirm(id, requestOptions) {
    return await Request.post(urls.change_auto_confirm + id, requestOptions).then(res => res.data);
};

async function getSelect() {
    const requestOptions = {};
    const res = await Request.get(urls.get_select, requestOptions);
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {
        console.log(e);
    }
    return {};
};