import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { sock_list } from '../../../endpoint';
import online from '../../../assets/images/online.png';

const SidebarLeft = () => {

    const [datalist, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalSock, setTotalSock] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        loadListContry(1);
    }, []);

    const loadListContry = (paged) => {
        sock_list.getCountryAll({'page': paged, 'per_page': 20}).then(res => {
            if (res.status == 'success') {
                if (paged == 1) {
                    setData(res.response);
                } else {
                    setData(datalist.concat(res.response));
                }
                setCurrent(paged + 1);
                setTotal(res.total);
                setTotalSock(res.totalSock);
                setData(datalist.concat(res.response));
                setLoading(false);
            }
        }).then(err => {
            setLoading(false);
            setLoadingMore(false);
        }).then(complete => {
            setLoading(false);
            setLoadingMore(false);
        });
    }

    const loadListContryMore = () => {
        setLoadingMore(true);
        loadListContry(current);
    }

    return (
        <div className="sidebar-left panel panel-default ">
            <div className="panel-heading text-center">
                <h6 className="remove-margin text-white">Total Socks5</h6>
            </div>
            <div className="panel-body">
                { loading ? 
                    (<div className="text-center mt-5 mb-5"><Spin /></div>) :
                    (
                        <div>
                            <ul className="list-inline user-online">
                                <li>
                                    <img src={online} width="35"/>
                                </li>
                                <li>
                                    <span>Online: {totalSock}</span>
                                </li>
                            </ul>
                            <hr className="mt-0"/>
                            <ul className="list-unstyled remove-margin pb-1">
                                {
                                    datalist.length > 0 && datalist.map((sock, key) => {
                                            return (
                                                <li key={sock.value}>
                                                    <span><img src={sock.country_img}/> {sock.text}</span>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                            {
                                total > 0 && (current - 1) * 20 < total ? (
                                    <div className='comment-load-more text-center'>
                                        <p>
                                            {
                                                loadingMore  ? (<Spin />) : (<a onClick={loadListContryMore} className='text-success'><b>Load More...</b></a>)
                                            }
                                        </p>
                                    </div>
                                ) : ''
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default SidebarLeft;
