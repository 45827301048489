import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { PlusOutlined, UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Input, Button, Select, Popconfirm, Spin, notification, message, Upload } from 'antd';
import { ModalForm, ProFormText, ProFormDigit } from '@ant-design/pro-form';
import { ticket, authenticator } from '../../endpoint';
import { CKEditor, Context, Bold, Essentials, Italic, Paragraph, ContextWatchdog } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Add new message
 *
 * @param dataForm
 */
const handleAdd = async (dataForm) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.addMessageFrontend(dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 300);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update message
 *
 * @param dataForm
 * @param id
 */
const handleUpdate = async (dataForm, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.updateMessageFrontend(id, dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 300);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete message
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await ticket.deleteMessageFrontend(selectedRows.id);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update contact
 *
 * @param dataForm
 * @param id
 */
const handleUpdateContact = async (dataForm, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.updateItemFrontend(id, dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete contact
 *
 * @param selectedRows
 */
const handleRemoveContact = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await ticket.deleteItemFrontend(selectedRows.id);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const ContactDetailComponent = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingComment, setLoadingComment] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [ticketData, setTicketData] = useState({});
    const [currentRow, setCurrentRow] = useState([]);
    const [ticketListComment, setTicketListComment] = useState([]);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [dataEditor, setDataEditor] = useState("");
    const [filesUpload, setFilesUpload] = useState([]);
    const [createModalVisible, handleModalVisible] = useState(false);
    const [createModalVisibleContact, handleModalVisibleContact] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const { id } = useParams();

    useEffect(() => {
        document.title = window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    ticket.getItemFrontend(id).then(res => {
                        if (res.status == 'success') {
                            setTicketData(res.response);
                            document.title = res.response.title + " - " + window?.title;
                            loadListComment();
                        } else {
                            history.push({pathname: '/contacts.html'});
                        }
                        setLoading(false);
                    }).then(res => {
                    });
                }
            }).then(res => {
            });
        }
    }, []);

    const loadListComment = () => {
        const hide = message.loading('Processing...');
        setLoadingComment(true);
        ticket.getListMessageFrontend(id, {'current': current, 'pageSize': pageSize}).then(res => {
            if (res.status == 'success') {
                if (current == 1) {
                    setTicketListComment(res.response);
                } else {
                    setTicketListComment(ticketListComment.concat(res.response));
                }
                setTotal(res.total);
                setCurrent(current + 1);
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoadingComment(false);
        }).then(complete => {
            hide();
            setLoadingComment(false);
        });
    }

    const onChangeFiles = (file) => {
        setFilesUpload(file.fileList);
    }

    const onRemoveFile = (file) => {
        let files = [];
        if (filesUpload.length > 0) {
            for(var i = 0; i < filesUpload.length; i++) {
                let tempFile = filesUpload[i];
                if (tempFile.uid != file.uid) {
                    files.push(tempFile);
                }
            }
        }
        setFilesUpload(files);
    }

    const beforeUpload = (file) => {
        if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/gif") {
            notification.open({
                message: 'Fail!',
                description:  `${file.name} not image file`,
                type: 'error'
            });
            return false;
        }
        const maxLimit = 3
        if (filesUpload.length > maxLimit) {
            notification.open({
                message: 'Fail!',
                description: 'Only 3 files allowed',
                type: 'error'
            });
            return false;
        }
      
        const maxFileSize = 3 * 1024 * 1024; // 3MB limit
        if (file.size > maxFileSize) {
            console.error("File size exceeds the limit of 3MB");
            notification.open({
                message: 'Fail!',
                description: `${file.name} file upload failed (exceeds 3MB)`,
                type: 'error'
            });
            return false;
        }
        return true;
    };

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            {
                loading ? (
                    <div className="text-center mt-5 mb-5"><Spin /></div>
                ) :
                (
                    <div>
                        <div className="panel panel-default panel-custom panel-contact">
                            <div className="panel-heading d-flex align-items-center justify-content-between">
                                <span>{ticketData?.title}</span>
                                <span>Status: {ticketData?.status == 1 ? (<span className="badge badge-success">Open</span>) : (<span className="badge badge-danger">Close</span>) }</span>
                            </div>
                            <div className="panel-body">
                                <div dangerouslySetInnerHTML={{__html: ticketData?.content}} />
                                <p>{ticketData?.date}</p>
                                {
                                    ticketData?.images && JSON.parse(ticketData.images).length > 0 ? (
                                        <div className='images'>
                                            <h5>Images Detail</h5>
                                            <ul>
                                                {
                                                    JSON.parse(ticketData.images).map((imageUrl, key) => 
                                                        <li key={'images' + key}>
                                                            <a href={imageUrl} target='_blank'>
                                                                <img src={imageUrl} style={{maxWidth: "100%"}} />
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    ) : ''
                                }
                                {
                                    ticketData?.status == 1 ? (
                                        <div className='action edit-contact'>
                                            <a className="pr-4"
                                                onClick={() => {
                                                    form1.resetFields();
                                                    setCurrentRow(ticketData);
                                                    handleModalVisibleContact(true);
                                                    setEditorLoaded(true);
                                                    setFilesUpload([]);
                                                    setDataEditor(ticketData.content);
                                                }}
                                            ><EditOutlined /> Edit</a>
                                            {
                                                ticketData?.is_delete ? (
                                                    <Popconfirm
                                                        title="Are you sure to delete this contact?"
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={ async () => {
                                                            await handleRemoveContact(ticketData);
                                                            setTimeout(function() {
                                                                location.reload();
                                                            }, 300);
                                                        }}
                                                    >
                                                        <a className='text-danger'><DeleteOutlined /> Delete</a>
                                                    </Popconfirm>
                                                ) : ''
                                            }
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                        <div className="comment-list-heading d-flex align-items-end justify-content-between mt-4 mb-2">
                            <h5 className="title title--fourth comment-list-heading__title">
                                Comments <span className="title-group__number">({total})</span>
                            </h5>
                            <div className="comment-list-heading__view-options">
                                {
                                    ticketData?.is_admin_reply == 1 && ticketData?.status == 1 ? (
                                        <Button type="primary" htmlType="button" className="medium" onClick={() => {
                                            form.resetFields();
                                            setCurrentRow(null);
                                            handleModalVisible(true);
                                            setEditorLoaded(true);
                                            setDataEditor('');
                                            setFilesUpload([]);
                                        }}>
                                            <PlusOutlined /> New Comment
                                        </Button>
                                    ) : ''
                                }
                            </div>
                        </div>
                        <div className="panel panel-default panel-custom panel-contact">
                            <div className="panel-body">
                                <ul className='comments comment-list__items'>
                                    {
                                        ticketListComment.length > 0 && ticketListComment.map((item, key) => 
                                            <li className="comment-item" key={item.id}>
                                                <div className="comment-item__inner">
                                                    <div className="comment-item__header">
                                                        <div className="user-icon-set -collapsed-trigger d-flex align-items-center">
                                                            <span className="user-icon-set__user-icon comment-item__user-icon">
                                                                <img src={window.base_url + (item?.is_admin == 1 ?  "uploads/admin-icon-vector.png": "uploads/avatar.jpg") } width="42px" height="42px" className="image image-circle"/>
                                                            </span>
                                                            <span className="user-icon-set__text comment-item__user-text">
                                                                <span className="user-icon-set__name d-block">{item?.is_admin == 1 ? 'Administrar' : item?.user_name}</span>
                                                                <span className="user-icon-set__sub-line d-block">{item?.date}</span>
                                                            </span>
                                                        </div>
                                                        {
                                                            item?.is_admin != 1 ? (
                                                                <div className="edit-comment action">
                                                                    <a className='mr-2'
                                                                        onClick={() => {
                                                                            form.resetFields();
                                                                            setCurrentRow(item);
                                                                            handleModalVisible(true);
                                                                            setEditorLoaded(true);
                                                                            setFilesUpload([]);
                                                                            setDataEditor(item.content);
                                                                        }}
                                                                    ><EditOutlined /> Edit</a>
                                                                    <Popconfirm
                                                                        title="Are you sure to delete this comment?"
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        onConfirm={ async () => {
                                                                            await handleRemove(item);
                                                                            setTimeout(function() {
                                                                                location.reload();
                                                                            }, 300);
                                                                        }} 
                                                                    >
                                                                        <a className='text-danger'><DeleteOutlined />  Delete</a>
                                                                    </Popconfirm>
                                                                </div>
                                                            ) : ""
                                                        }
                                                    </div>
                                                    <div className="comment-item__container">
                                                        <div className="loom comment-content">
                                                            <div className="markdown-body">
                                                                <blockquote>
                                                                    <div dangerouslySetInnerHTML={{__html: item?.content}} />
                                                                </blockquote>
                                                                {
                                                                    item?.images && JSON.parse(item?.images).length > 0 ? (
                                                                        <div className='images'>
                                                                            <h5>Images Detail</h5>
                                                                            <ul>
                                                                                {
                                                                                    JSON.parse(item.images).map((imageUrl, key) => 
                                                                                        <li key={'images-detail-' + key}>
                                                                                            <a href={imageUrl} target='_blank'>
                                                                                                <img src={imageUrl} style={{maxWidth: "100%"}} />
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                                {
                                    total > 0 && (current - 1) * pageSize < total ? (
                                        <div className='comment-load-more text-center'>
                                            <p>
                                                {
                                                    loadingComment  ? (<Spin />) : (<a onClick={loadListComment}>Load More...</a>)
                                                }
                                            </p>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>

                        {/*Form Add new and Edit Comment*/}
                        <ModalForm
                            id="form-add-contact"
                            title="New Contact"
                            width="640px"
                            form={form}
                            submitter={false}
                            visible={createModalVisible}
                            onVisibleChange={handleModalVisible}
                            onFinish={async (value) => {
                            }}
                        >
                            {
                                editorLoaded ? (
                                    <div className="mb-4 ant-form-item-label d-block">
                                        <label className="ant-form-item-required">Content:</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="content"
                                            style={{width: "100%"}}
                                            config={{
                                                //removePlugins: ['Image', 'MediaEmbed']
                                            }}
                                            data={dataEditor}
                                            onReady={(editor) => {
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDataEditor(data);
                                            }}
                                        />
                                    </div>
                                ) : ""
                            }
                            <div className='form-group ant-form-item-label mt-3'>
                                <label className='d-block'>Images (3 images):</label>
                                <Upload beforeUpload={() => {beforeUpload(); return false;}} maxCount="3" multiple={true} onChange={onChangeFiles} onRemove={onRemoveFile}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className="upload-btn"
                                    >
                                        Add images
                                    </Button>
                                    <small className='d-block text-danger'>Please upload image maxsize 3MB.</small>
                                </Upload>
                            </div>
                            <div className='text-right'>
                                <Button
                                    type="primary"
                                    key="submitForm"
                                    loading={loadingButton}
                                    onClick={async () => {
                                        const value = form.getFieldsValue();
                                        form.submit();
                                        if (dataEditor) {
                                            var isVal = true;
                                            const formData = new FormData();
                                            formData.append('content', dataEditor);
                                            formData.append('ticket_id', id);
                                            if (filesUpload.length > 0) {
                                                for(var i = 0; i < filesUpload.length; i++) {
                                                    let tempFile = filesUpload[i];
                                                    if (beforeUpload(tempFile)) {
                                                        formData.append("image" + (i + 1), tempFile.originFileObj);
                                                    } else {
                                                        isVal = false;
                                                    }
                                                }
                                            }
                                            if (isVal) {
                                                setLoadingButton(true);
                                                if (currentRow?.id) {
                                                    const success = await handleUpdate(formData, currentRow?.id);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                    }
                                                    setLoadingButton(false);
                                                } else {
                                                    const success = await handleAdd(formData);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                    }
                                                    setLoadingButton(false);
                                                }
                                            }
                                        } else {
                                            notification.open({
                                                message: 'Fail!',
                                                description: "Please enter content",
                                                type: 'error'
                                            });
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </ModalForm>

                        {/*Form Add new and Edit Contact*/}
                        <ModalForm
                            id="form-add-contact"
                            title="New Contact"
                            width="768px"
                            form={form1}
                            submitter={false}
                            visible={createModalVisibleContact}
                            onVisibleChange={handleModalVisibleContact}
                            onFinish={async (value) => {
                            }}
                        >
                            <ProFormText
                                rules={[
                                    {
                                        required: true,
                                        message: "Title is required"
                                    },
                                ]}
                                initialValue={currentRow?.title}
                                placeholder=""
                                width="full"
                                name="title"
                                label="Title:"
                            />
                            {
                                editorLoaded ? (
                                    <div className="mb-4 ant-form-item-label d-block">
                                        <label className="ant-form-item-required">Content:</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="content"
                                            style={{width: "100%"}}
                                            config={{
                                                //removePlugins: ['Image', 'MediaEmbed']
                                            }}
                                            data={dataEditor}
                                            onReady={(editor) => {
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDataEditor(data);
                                            }}
                                            
                                        />
                                    </div>
                                ) : ""
                            }
                            <div className='form-group ant-form-item-label mt-3'>
                                <label className='d-block'>Images (3 images):</label>
                                <Upload beforeUpload={() => {beforeUpload(); return false;}} maxCount="3" multiple={true} onChange={onChangeFiles} onRemove={onRemoveFile}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className="upload-btn"
                                    >
                                        Add images
                                    </Button>
                                    <small className='d-block text-danger'>Please upload image maxsize 3MB.</small>
                                </Upload>
                            </div>
                            <div className='text-right'>
                                <Button
                                    type="primary"
                                    key="submitForm"
                                    loading={loadingButton}
                                    onClick={async () => {
                                        const value = form1.getFieldsValue();
                                        form1.submit();
                                        if (value.title && dataEditor) {
                                            var isVal = true;
                                            const formData = new FormData();
                                            formData.append('content', dataEditor);
                                            formData.append('title', value.title);
                                            if (filesUpload.length > 0) {
                                                for(var i = 0; i < filesUpload.length; i++) {
                                                    let tempFile = filesUpload[i];
                                                    if (beforeUpload(tempFile)) {
                                                        formData.append("image" + (i + 1), tempFile.originFileObj);
                                                    } else {
                                                        isVal = false;
                                                    }
                                                }
                                            }
                                            if (isVal) {
                                                setLoadingButton(true);
                                                const success = await handleUpdateContact(formData, currentRow?.id);
                                                if (success) {
                                                    form1.resetFields();
                                                    handleModalVisible(false);
                                                    setTimeout(function() {
                                                        location.reload();
                                                    }, 300);
                                                }
                                                setLoadingButton(false);
                                            }
                                        } else {
                                            notification.open({
                                                message: 'Fail!',
                                                description: "Please enter all infomation",
                                                type: 'error'
                                            });
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </ModalForm>
                    </div>
                )
            }
        </div>
    );
}
export default ContactDetailComponent;