import React, { useState, useEffect } from 'react';
import { useHistory} from "react-router-dom";
import { Button, message, notification, Form, Checkbox, Input, Spin } from 'antd';
import { account, setting_menu, authenticator } from '../../../../endpoint';

const SettingMenuComponent = () => {
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [settingMenu, setSettingMenu] = useState({});
    const [form] = Form.useForm();
    const history = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');
    const listMenu = [
        { label: "Buy Socks5", value: "buy_socks5" },
        { label: "Premium Socks5", value: "premium_socks5" },
        { label: "Socks5 Daily", value: "socks5_daily" },
        { label: "Socks5 List", value: "socks5_list" },
        { label: "Socks5 24H", value: "socks5_24h" },
        { label: "Check Socks5", value: "check_socks5" },
        { label: "Contacts", value: "contacts" },
        { label: "My account", value: "my_account" },
        { label: "API", value: "api" },
        { label: "Soft Client", value: "soft_client" },
    ];

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }

        setting_menu.getData().then(res => {
            if (res.status == 'success') {
                if (res.response != null) {
                    setTimeout(function() {
                        setSettingMenu(res.response);
                        var response = res.response;
                        listMenu.forEach(function(item) {
                            if ($("#" + item.value).length > 0 && response[item.value] == 1) {
                                $("#" + item.value).parent().trigger('click');
                            }
                        });
                    }, 500);
                }
                setLoadingData(false);
            }
        });
    }, []);

    /**
     * Save data
     *
     * @param fields
     */
    const onFinish = () => {
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            setting_menu.updateItem(settingMenu).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    };

    /**
     * On change checkbox
     *
     * @param field
     * @param e
     */
    const onChange = (field, e) => {
        const setting = settingMenu;
        setting[field] = e.target.checked ? 1 : 0;
        $("#" + field).prop('checked', e.target.checked);
        setSettingMenu(setting);
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            {
                                loadingData && (<div className="text-center mt-5"><Spin /></div>)
                            }
                            {
                                !loadingData && (
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-1"></div>
                                        <div className="col-12 col-md-12 col-lg-10">
                                            {   
                                                listMenu.map((item) => {
                                                    return (
                                                        <div className="form-group" key={item.value}>
                                                            <Checkbox
                                                            key={item.label}
                                                            id={item.value}
                                                            onChange={(e) => onChange(item.value, e)}
                                                            >
                                                                {item.label}
                                                            </Checkbox>
                                                        </div>
                                                    );
                                            })}
                                            <div className="row">
                                                <div className="col-md-12 text-right">
                                                    <Button type="primary" onClick={onFinish} loading={loading} className="medium">Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SettingMenuComponent;
