import React, { useState, useEffect } from 'react';
import { Tabs, Spin, Modal } from 'antd';
import { Link } from "react-router-dom";
import SidebarLeft from './include/SidebarLeft';
import SidebarRight from './include/SidebarRight';
import { plan, banner, account } from '../../endpoint';

const { TabPane } = Tabs;
const Home = () => {
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [data, setData] = useState([]);
    const [notification, setNotification] = useState("");
    const [dataBanner, setBanner] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        document.title = "Home - " + window?.title;

        plan.getListPlan().then(res => {
            if (res.status == 'success') {
                setData(res.response);
                setUserInfo(res.info);
                setLoading(false);
            }
        });

        banner.get_list_banner({position: 'banner_top'}).then(res => {
            if (res.status == 'success') {
                setBanner(res.response);
            }
        });

        account.notification_home().then(res => {
            if (res.status == 'success' && res.response) {
                setNotification(nl2br(res.response.config_description));
                var lastclear = localStorage.getItem('last_time');
                var timeNow  = (new Date()).getTime();
                if (!lastclear || (timeNow - lastclear) > 1000 * 60 * 60 * 24 * 24) {
                    setIsModalVisible(true);
                    localStorage.setItem('last_time', timeNow);
                }
            }
        });
    }, []);

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
    };

    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2">
                    <SidebarLeft />
                </div>
                <div className="col-md-8">
                    <div className="content home-page">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                        <div className={(loading ? " d-none" : "")}>
                            <div className="servicer">
                                <div className="row">
                                    {
                                        dataBanner && dataBanner.length > 0 && dataBanner.map((item, key) => 
                                            <div className={"col-12 col-sm-" + (item.is_full == 1 ? '12' : '6')} key={item.id}>
                                                <a onClick={()=> handleShowModalAds(item)}>
                                                    <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 PREMIUM" className="plan panel panel-default">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 PREMIUM</h3>
                                <div className="panel-body">
                                    <p className="remove-margin">
                                        <Link to="/socks5-premium.html?all=1"><b>For example, PREMIUM 200 SOCKS5: </b></Link>
                                        You will have 60days get socks5. After you get enough 200 socks5 or after 60 days from the date of account activation your account will expired. Numbers of socks5 will get at <Link to="/socks5-premium.html"><b>PREMIUM SOCKS5</b></Link>
                                    </p>
                                    <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-premium">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-premium</Link></p>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered remove-margin table-plan list-premium table-list-mobile">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Daily Expired</th>
                                                        <th>Price($)</th>
                                                        <th>Credit Expired</th>
                                                        <th>Total Socks5 Receive</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.Premium && data?.Premium.length > 0 && data?.Premium.map((item, key) => 
                                                            <tr key={item.plan_id}>
                                                                <td data-title="Plan">{item.plan_name}</td>
                                                                <td data-title="Daily Expired">{item.plan_expired == 0 ? window.confirm.number_day + ' Days' : item.plan_expired + ' Days'}</td>
                                                                <td data-title="Price($)">{item.plan_price} $</td>
                                                                <td data-title="Credit Expired">{"Until Credit = 0"}</td>
                                                                <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 DAILY" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                <div className="panel-body">
                                    <p className="remove-margin">
                                        <Link to="/buy-socks5.html"><b>For example, Daily 90 Socks5: </b></Link>
                                        You can get maximium 90 socks for a day. If you don't use, you will lose 90 socks in a day ( non-cumulative socks5). Numbers of socks5 will get at <Link to="/view-sock.html"><b>SOCKS5 DAILY</b></Link>
                                        <br/>Plan Daily 90 Socks5 30 days you will maximum: 90*30=2700sock5
                                    </p>
                                    <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-daily">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-daily</Link></p>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Tabs defaultActiveKey="30day">
                                            <TabPane tab="15 Days" key="15day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="30 Days" key="30day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="365 Days" key="365day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 LIST" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                <div className="panel-body">
                                    <p className="remove-margin">
                                        <Link to="/buy-socks5.html"><b>For example, Plan List 200 Socks5: </b></Link>
                                        You will have 200 socks5 per day ( non-cumulative socks5). Numbers of socks5 will get at <Link to="/socks5-list.html"><b>SOCKS5 LIST</b></Link>
                                        <br/>Plan LIST 200 Socks5 30 days you will have maximum: 200*30 = 6000 Socks5
                                    </p>
                                    <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-list">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-list</Link></p>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Tabs defaultActiveKey="list-30day">
                                            <TabPane tab="15 Days" key="list-15day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="30 Days" key="list-30day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="365 Days" key="list-365day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Reset Free</th>
                                                                <th>Reset(-1)</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                        <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>        
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <SidebarRight />
                </div>
            </div>
            <div className="page-bottom home-payment-icon">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://perfectmoney.is/login.html" className='payment1 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto  text-center">
                        <a href="https://login.wmtransfer.com/" className='payment2 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://blockchain.info/" className='payment3 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment4 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment5 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment6 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment7 payment' target="_blank"></a>
                    </div>
                </div>
            </div>
            <Modal title="Notification" visible={isModalVisible} onCancel={handleCancel} footer={null} width={640}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: notification }}></p>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Home;
