import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormDateTimePicker,ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { users, plan, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';
import UserUpdate from './userUpdate';

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await user.addItem({ ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param user_id
 */
const handleUpdate = async (fields, user_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.updateItem({ ...fields, id: user_id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await users.deleteItems({
            lists: selectedRows.map((row) => row.user_id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Reset node
 *
 * @param selectedRows
 */
const handleReset = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await users.resetItem(selectedRows.user_id, {});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully.',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.changeStatus(record.user_id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status Api
 *
 * @param status
 * @param record
 */
const handledChangeStatusApi = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.changeStatusApi(record.user_id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update Plan
 *
 * @param selectedRows
 * @param planId
 */
const handleUpdatePlan = async (selectedRows, planId) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await users.updatePlanItem(selectedRows.user_id, {plan_id: planId});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change max device
 *
 * @param data
 * @param id
 */
const handledUpdateMaxDevice = async (data, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.updateMaxDevice(id, {'device': data.max_device});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change max confirm
 *
 * @param data
 * @param id
 */
const handledUpdateMaxConfirm = async (data, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.updateMaxConfirm(id, {'max_confirm': data.max_confirm});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change current confirm
 *
 * @param data
 * @param id
 */
const handledUpdateCurrentConfirm = async (data, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.updateCurrentConfirm(id, {'current_confirm': data.current_confirm});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Auto confirm
 *
 * @param status
 * @param record
 */
const handledChangeAutoConfirm = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await users.updateAutoConfirm(record.user_id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Popover Item
 *
 * @param props
 */
const PopoverItem = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [value, setValue] = useState(props.text);
    const toggle = () => setPopoverOpen(!popoverOpen);
    return (
        <div>
            <span className="popover-edit" id={`Popover-${props.id}`} onClick={toggle.bind(null)}>{value} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverOpen} target={`Popover-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Max Device
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ max_device: value }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateMaxDevice(data, props.id)){
                                    setPopoverOpen(false);
                                    setValue(data.max_device);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Max Device is required"
                                },
                            ]}
                            label="Max Device" 
                            name="max_device" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

/**
 * Popover max confirm Item
 *
 * @param props
 */
const PopoverMaxConfirmItem = (props) => {
    const [popoverOpenMaxConfirm, setPopoverOpenMaxConfirm] = useState(false);
    const [value, setValue] = useState(props.text);
    const toggle = () => setPopoverOpenMaxConfirm(!popoverOpenMaxConfirm);
    return (
        <div>
            <span className="popover-edit" id={`max-confirm-${props.id}`} onClick={toggle.bind(null)}>{value} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverOpenMaxConfirm} target={`max-confirm-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Max confirm
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ max_confirm: value }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateMaxConfirm(data, props.id)){
                                    setPopoverOpenMaxConfirm(false);
                                    setValue(data.max_confirm);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Max confirm is required"
                                },
                            ]}
                            label="Max confirm" 
                            name="max_confirm" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

/**
 * Popover current confirm Item
 *
 * @param props
 */
const PopoverCurrentConfirmItem = (props) => {
    const [popoverOpenCurrentConfirm, setPopoverOpenCurrentConfirm] = useState(false);
    const [value, setValue] = useState(props.text);
    const toggle = () => setPopoverOpenCurrentConfirm(!popoverOpenCurrentConfirm);
    return (
        <div>
            <span className="popover-edit" id={`current-confirm-${props.id}`} onClick={toggle.bind(null)}>{value} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverOpenCurrentConfirm} target={`current-confirm-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Current confirm
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ current_confirm: value }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateCurrentConfirm(data, props.id)){
                                    setPopoverOpenCurrentConfirm(false);
                                    setValue(data.current_confirm);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Current confirm is required"
                                },
                            ]}
                            label="Current confirm" 
                            name="current_confirm" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

const UserComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [updateModalVisible, handleUpdateModalVisible] = useState(false);
    const [updatePlanModalVisible, handleUpdatePlanModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [plansList, setPlansList] = useState([]);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history = useHistory();
    const [form] = Form.useForm();
    const [formUpdatePlan] = Form.useForm();
    const timeFormat = "DD/MM/YYYY HH:mm";
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());

        const data = await users.getList(parameters);
        return data;
    }

    const plans = async () => {
        const data = await plan.getSelect();
        return data;
    }

    const columns = [
        {
            title: "Username",
            dataIndex: 'user_name',
            sorter: false,
            valueType: 'text',
            tip: 'The user name is the unique key',
            initialValue: paramQuery?.user_name,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            },
        },
        {
            title:'Email',
            dataIndex: 'user_mail',
            valueType: 'email',
            initialValue: paramQuery?.user_mail,
            tip: 'The user name is the unique key'
        },
        {
            title: "Group",
            dataIndex: 'user_groupid',
            initialValue: paramQuery?.user_groupid,
            valueEnum: {
                1: {
                    text:'Administrator',
                },
                2: {
                    text: "V.I.P"
                },
                3: {
                    text: "Member"
                }
            },
        },
        {
            title: "Plan",
            dataIndex: 'plan_name',
            valueType: 'select',
            valueEnum: {
                0: {
                    text:'No',
                    status: 'Default',
                },
                1: {
                    text: "Yes",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <a key="update_plan" className="line-bottom"
                    onClick={() => {
                        setCurrentRow(record);
                        handleUpdatePlanModalVisible(true);
                    }}
                >{record.plan_name ?? "None"}</a>
            ]
        },
        {
            title: "Number Sock",
            dataIndex: 'number_socks',
            hideInSearch: true
        },
        {
            title: "Use Today",
            dataIndex: 'tracking_count',
            hideInSearch: true,
            render: (_, record) => [
                <Popconfirm
                    key={record.user_id}
                    title="Are you sure to reset this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleReset(record);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a className="line-bottom" key="reset">{record.tracking_count ?? 0}</a>
                </Popconfirm>
            ]
        },
        {
            title: "Expired",
            dataIndex: 'user_exdate_format',
            hideInSearch: true
        },
        {
            title: "Status",
            dataIndex: 'user_status',
            valueType: 'select',
            initialValue: paramQuery?.user_status,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatus(value, record)} key={record.user_id} defaultChecked={record.user_status  == 1 ? true : false} />
            ]
        },
        {
            title: "Status API",
            dataIndex: 'status_api',
            valueType: 'select',
            initialValue: paramQuery?.status_api,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatusApi(value, record)} key={record.user_id} defaultChecked={record.status_api  == 1 ? true : false} />
            ]
        },
        {
            title: "Max Device",
            dataIndex: 'max_device',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverItem key={"max_device" + record.user_id} id={record.user_id} text={record.max_device} />
            ]
        },
        {
            title: "Pause Confirm",
            dataIndex: 'auto_confirm',
            valueType: 'select',
            initialValue: paramQuery?.auto_confirm,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeAutoConfirm(value, record)} key={record.user_id} defaultChecked={record.auto_confirm  == 1 ? true : false} />
            ]
        },
        {
            title: "Max Confirm",
            dataIndex: 'max_confirm',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverMaxConfirmItem key={"max_confirm" + record.user_id} id={record.user_id} text={record.max_confirm} />
            ]
        },
        {
            title: "Current Confirm",
            dataIndex: 'current_confirm',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverCurrentConfirmItem key={"current_confirm" + record.user_id} id={record.user_id} text={record.current_confirm} />
            ]
        },
        {
            title: "Time Login",
            dataIndex: 'user_lastlogin_show',
            hideInSearch: true
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="edit"
                    onClick={() => {
                        handleUpdateModalVisible(true);
                        setCurrentRow(record);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.user_id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete"><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Users Manager"
                actionRef={actionRef}
                request={list}
                rowKey="user_id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 20)
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-add-user"
                title="Add new User"
                width="500px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    const success = await handleAdd(value);
                    if (success) {
                        form.resetFields();
                        handleModalVisible(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Username is required"
                        },
                        {
                            min: 6,
                            message: "Password minlength 6 charaters"
                        }
                    ]}
                    width="lg"
                    name="user_name"
                    label="User Name:"
                />
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Email address is required"
                        },
                        {
                            type: "email",
                            message: "The input is not valid E-mail!"
                        }
                    ]}
                    width="lg"
                    name="user_mail"
                    label="Email address:"
                    type="email"
                />
                <ProFormSelect
                    width="lg"
                    name="user_groupid"
                    label="User Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Administrator', value: '1'},
                        { label: 'V.I.P', value: '2' },
                        { label: 'Member', value: '3' }
                    ]}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your type!' 
                        }
                    ]}
                />
                <ProFormSelect
                    showSearch
                    width="lg"
                    name="plan"
                    label="Plan"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={plans}
                    placeholder="Please select a plan"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your plan!' 
                        }
                    ]}
                />
                <ProFormDigit 
                    label="Sock Number " 
                    name="number_socks" 
                    width="lg" 
                    min={0}
                    initialValue={currentRow?.number_socks}
                />
                <ProFormText.Password
                    rules={[
                        {
                            required: true,
                            message: "Password is required"
                        },
                        {
                            min: 6,
                            message: "Password minlength 6 charaters"
                        },
                    ]}
                    width="lg"
                    name="user_pass"
                    label="Password:"
                />
                <ProFormDateTimePicker 
                    name="datetime" 
                    label="Expired: " 
                    width="lg"
                    name="user_exdate"
                    fieldProps={{
                        showTime: { format: timeFormat },
                        format: [timeFormat]
                    }}
                />
            </ModalForm>

            {/*Form Update*/}
            <UserUpdate 
                onSubmit={async (value) => {
                    const success = await handleUpdate(value, currentRow?.user_id);
                    if (success) {
                        handleUpdateModalVisible(false);
                        setCurrentRow(undefined);

                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
                onCancel={() => {
                    handleUpdateModalVisible(false);
                    setCurrentRow(undefined);
                }}
                updateModalVisible={updateModalVisible}
                values={currentRow || {}}
            />

            {/*Form Update Plan*/}
            <ModalForm
                id="form-update-plan-user"
                title="Update Plan"
                width="500px"
                form={formUpdatePlan}
                visible={updatePlanModalVisible}
                onVisibleChange={handleUpdatePlanModalVisible}
                onFinish={async (value) => {
                    const success = await handleUpdatePlan(currentRow, value.plan_id.value);
                    if (success) {
                        form.resetFields();
                        handleUpdatePlanModalVisible(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormSelect
                    showSearch
                    width="lg"
                    name="plan_id"
                    label="Plan"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={plans}
                    placeholder="Please select a plan"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your plan!' 
                        }
                    ]}
                />
            </ModalForm>

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.user_id && (
                    <ProDescriptions 
                        title={currentRow?.user_name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.user_id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserComponent;
