import React, { useState, useEffect } from 'react';

const Footers = () => {
    const d = new Date();
    const year = d.getFullYear();

    useEffect(() => {

    }, []);

    return (
        <footer>
            <div className="footer-content">
                <div className="container">
                    <div className="col-md-12">
                        <p className="remove-margin">Copyright © 2023 - <a href="https://shopsocks5.com/" className="copyright">Shopsocks5.com</a> | All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footers;
