import { Request } from '../_until';
import { notification } from 'antd';

export const plan = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeStatus,
    changeSort,
    changeShow,
    changeReset,
    deleteItems,
    getSelect,
    getListPlan,
    changeNumberConfirm,
    changeAutoConfirmTime
};

const urls = {
    get_list: `/plan`,
    get_item: `/plan/get/`,
    get_select: `/plan/get_select`,
    add_item: `/plan/add`,
    edit_item: `/plan/update`,
    change_status: `/plan/change_status/`,
    change_sort: `/plan/change_sort/`,
    change_show: `/plan/change_show/`,
    change_reset: `/plan/change_reset/`,
    change_number_confirm: `/plan/change_number_confirm/`,
    change_auto_confirm_time: `/plan/change_auto_confirm_time/`,
    delete_item: `/plan/delete`,
    get_plan: `plan`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function changeShow(id, requestOptions) {
    return await Request.post(urls.change_show + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function getSelect() {
    const requestOptions = {};
    const res = await Request.get(urls.get_select, requestOptions);
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

async function changeSort(plan_id, requestOptions) {
    return await Request.post(urls.change_sort + plan_id, requestOptions).then(res => res.data);
};

async function changeReset(plan_id, requestOptions) {
    return await Request.post(urls.change_reset + plan_id, requestOptions).then(res => res.data);
};

async function changeNumberConfirm(plan_id, requestOptions) {
    return await Request.post(urls.change_number_confirm + plan_id, requestOptions).then(res => res.data);
};

async function changeAutoConfirmTime(plan_id, requestOptions) {
    return await Request.post(urls.change_auto_confirm_time + plan_id, requestOptions).then(res => res.data);
};

function getListPlan() {
    return Request.get_website(urls.get_plan, { show: 1 }).then(res => res.data);
};