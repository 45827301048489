import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, notification, Modal, message, Spin } from 'antd';
import { LockOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { account, authenticator } from '../../endpoint';

const MyAccount = () => {
    const history = useHistory();
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingTelegram, setLoadingTelegram] = useState(false);
    const [loadingConvert, setLoadingConvert] = useState(false);
    const [isModalConvertPlan, setIsModalConvertPlan] = useState(false);
    const [profile, setProfile] = useState([]);
    const [telegram, setTelegram] = useState([]);
    const [connect, setConnect] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    const onFinish = (values) => {
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            account.change_password(values).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    };

    const checkStatusTelegram = () => {
        account.telegram_reset_status().then(res => {
            if (res.status == 'success' && res.success) {
                if (connect != null) {
                    clearInterval(connect);
                }
                getTelegramInfo();
                setLoadingTelegram(false);
            }
        });
    }

    const telegramConnection = () => {
        window.open("https://t.me/shopsock5_bot?start=" + profile?.user_id);
        setLoadingTelegram(true);
        account.telegram_connection().then(res => {
            if (res.status == 'success') {
                checkStatusTelegram();
                setConnect(setInterval(checkStatusTelegram, 10*1000));
            }
        });
    }

    const disconnectTelegramConnection = () => {
        setLoadingTelegram(true);
        account.telegram_disconnect().then(res => {
            if (res.status == 'success') {
                account.telegram_info().then(res => {
                    if (res.status == 'success') {
                        getTelegramInfo();
                        setLoadingTelegram(false);
                    }
                });
            }
        });
    }

    const getTelegramInfo = () => {
        account.telegram_info().then(res => {
            if (res.status == 'success') {
                setTelegram(res.response);
            }
        });
    }

    const getProfile = () => {
        setLoadingProfile(true);
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
                setLoadingProfile(false);
            }
        });
    }

    const covertPlan = () => {
        const hide = message.loading('Processing...');
        try {
            setLoadingConvert(true);
            account.convert_plan({}).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Convert successfully',
                        type: 'success'
                    });
                    setIsModalConvertPlan(false);
                    getProfile();
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoadingConvert(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    }

    useEffect(() => {
        document.title = "Myaccount - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                            setLoadingProfile(false);
                        }
                    });

                    getTelegramInfo();
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }
    
    return (
        <div className="container">
            {
                loadingProfile ? 
                (<div className="text-center mt-5"><Spin /></div>) :
                (
                    <div className="row">
                        <div className="col-sm-1 col-md-1"></div>
                        <div className="col-sm-10 col-md-10">
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">My Account</div>
                                        <div className="panel-body">  
                                            <div className="info-login">
                                                <ul className="list-unstyled">
                                                    <li>Email: <span>{profile?.user_mail}</span></li>
                                                    <li>User: <span>{profile?.user_name}</span></li>
                                                    <li>Plan: 
                                                        <span>{profile?.plan_name}</span>
                                                        {
                                                            profile?.plan_name == 'List' || profile?.plan_name == 'Daily' ? (
                                                                <Button type="primary" onClick={() => {setIsModalConvertPlan(true)}} className="small text-white ml-2">Convert</Button>
                                                            ) : ''
                                                        }
                                                    </li>
                                                    <li>API Token: <span>{profile?.api_token}</span></li>
                                                    <li>Expired: <span>{profile?.user_exdate}</span> ({profile?.user_exdate_left})</li>
                                                    <li>Used today: <span>{profile?.count}</span></li>
                                                    <li>Total Reset Limit This Month: <span>{profile?.count_reset}</span></li>
                                                    <li className="d-none">Last Login: <span>{profile?.user_lastlogin}</span></li>
                                                    {
                                                        profile?.plan_name == 'Socks5 All' ? (
                                                            <li>Limit: <span>Unlimited socks5 per day</span></li>
                                                        ) : (
                                                            <li>Limit: <span>{profile?.count} of {profile?.limit} socks</span></li>
                                                        )
                                                    }
                                                    <li className="d-none">Bonus: <span>{profile?.bonus}$</span></li>
                                                    <li>
                                                        <Link to="/histories.html" className="medium btn btn-primary mr-3 mb-3 d-none">
                                                            Histories
                                                        </Link>
                                                        <Link to="/orders.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Orders History
                                                        </Link>
                                                        <Link to="/reset.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Reset History
                                                        </Link>
                                                        <Link to="/convert.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Convert History
                                                        </Link>
                                                        <Link to="/2fa.html" className="medium btn btn-primary mr-3 mb-3">
                                                            2FA Authentication <AppstoreAddOutlined className="site-form-item-icon" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <p>For API access please contact email: <b>{window.confirm?.support_email}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Change Password</div>
                                        <div className="panel-body">
                                            <Form
                                                name="basic"
                                                className="form-horizontal mt-3 form"
                                                onFinish={onFinish}
                                            >
                                                <div className="form-group">
                                                    <Form.Item
                                                        label="Current password:"
                                                        labelCol={{span: 24}}
                                                        name="user_pass"
                                                        rules={[{ required: true, message: 'Please input current password!' }]}
                                                    >
                                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Current Password" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-group">
                                                    <Form.Item
                                                        label="New password:"
                                                        labelCol={{span: 24}}
                                                        name="user_pass_new"
                                                        rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'New Password must be minimum 6 characters.' }]}
                                                    >
                                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New password" />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-group">
                                                    <Form.Item
                                                        label="Repeat password:"
                                                        labelCol={{span: 24}}
                                                        name="user_pass_new_re"
                                                        rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'Repeat password must be minimum 6 characters.' }]}
                                                    >
                                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Repeat password" />
                                                    </Form.Item>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-right">
                                                        <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12 col-md-12">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Connect Telegram</div>
                                        <div className="panel-body">
                                            <div className="info-login">
                                                <div className="box-card-icon mb-3 d-flex align-items-center">
                                                    <div className="icon pr-2">
                                                        <img width="48px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/1200px-Telegram_2019_Logo.svg.png" alt="wallet-img" />
                                                    </div>
                                                    <div className="description">
                                                        <h4 className="text-base mb-2">Connect Telegram</h4>
                                                        <p className="description">Connect Telegram receive notification</p>
                                                    </div>
                                                </div>
                                                { telegram?.telegram_id ? 
                                                    (<p>
                                                        You are connected to Telegram: 
                                                        <b>{ telegram.telegram_info?.first_name } - { telegram.telegram_info?.last_name }</b>
                                                    </p>) : ''
                                                }
                                                <div className="box-card-btn mt-2">
                                                    { 
                                                        telegram?.telegram_id ? <Button type="danger" onClick={disconnectTelegramConnection} loading={loadingTelegram} className="medium">Disconnect</Button> :
                                                            <Button type="primary" onClick={telegramConnection} loading={loadingTelegram} className="medium">Connect</Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal title="Convert" visible={isModalConvertPlan} onCancel={() => {setIsModalConvertPlan(false)}} footer={null} width={480}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mb-0'>1 account will be able to convert plans 3 times a month</p>
                                    <p className='text-center mb-0'>You have converted <span className='text-danger'>{profile?.count_convert_plan}</span> times during the month</p>
                                    <p className='text-center text-danger'><b>{profile?.plan_name == "List" ? "Daily" : "List"}</b></p>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6'>
                                            <Button type="primary" onClick={covertPlan} loading={loadingConvert} className="d-block medium">Convert</Button>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <Button type="danger" onClick={() => {setIsModalConvertPlan(false)}} className="d-block medium">Cancel</Button>
                                        </div>
                                    </div>
                                    <hr />
                                    <p className="text-center mt-3">
                                        <span className="text-danger"><b>Support</b></span><br/> 
                                        Email: {window.confirm?.support_email}<br/>
                                        Skype: {window.confirm?.skype}
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
            } 
        </div>
    );
}

export default MyAccount;
