import { Request } from '../_until';
import { notification } from 'antd';

export const faqs = {
    getList,
    getListFrontend,
    getItemFrontend,
    getItem,
    updateItem,
    addItem,
    deleteItems,
    changeStatus,
    changeSort,
    getUploadUrl
};

const urls = {
    get_list: `/faqs`,
    get_list_frontend: `getfaq`,
    get_detail_frontend: `getfaq/get`,
    get_item: `/faqs/get/`,
    add_item: `/faqs/add`,
    edit_item: `/faqs/update`,
    delete_item: `/faqs/delete`,
    change_status: `/faqs/change_status/`,
    change_sort: `/faqs/change_sort/`,
    upload: `/faqs/upload`
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

function getListFrontend() {
    return Request.get_website(urls.get_list_frontend, {}).then(res => res.data);
};

function getItemFrontend(slug) {
    return Request.post_website(urls.get_detail_frontend, {'slug': slug}).then(res => res.data);
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function changeSort(id, requestOptions) {
    return await Request.post(urls.change_sort + id, requestOptions).then(res => res.data);
};

function getUploadUrl() {
    return window.base_url + 'api' + urls.upload;
}