import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { CloseCircleOutlined  } from '@ant-design/icons';

const Cancel = () => {
    const history = useHistory();

    useEffect(() => {
        document.title = "Cancel - " + window?.title;
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="mt-5 pt-5 pb-5 cancel-box" style={{maxWidth: "640px", background: "#fff", margin: "0 auto", width: "100%"}}>
                        <CloseCircleOutlined size="large" style={{color: "#db4437"}} />
                        <p style={{fontSize: "28px", fontWeight: "bold", marginBottom: "0px"}}>Failed</p>
                        <p style={{fontSize: "16px"}}>Unfortunately payment was rejected.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cancel;