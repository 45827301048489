import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spin } from 'antd';
import { faqs } from '../../endpoint';
import faqsImage from '../../assets/images/faqs.png';

const FAQsList = () => {
    const [loading, setLoading] = useState(true);
    const [dataFaqs, setDataFaqs] = useState([]);

    useEffect(() => {
        document.title = "Instructions - " + window?.title;
        faqs.getListFrontend().then(res => {
            if (res.status == 'success') {
                setDataFaqs(res.response);
            }
            setLoading(false);
        }).then(res => {
        });
    }, []);
    
    return (
        <div className="container">
            {
                loading ? 
                (<div className="text-center mt-5"><Spin /></div>) :
                (
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="panel panel-default panel-custom">
                                <div className="panel-body">  
                                    <div className="info-login">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <img src={faqsImage} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <h3 className="text-bold mt-4"><b>Instructions</b></h3>
                                                <ul className="faqs-list">
                                                    {
                                                        dataFaqs && dataFaqs.length > 0 && dataFaqs.map((item, key) => 
                                                            <li key={item.id}><Link to={'/faq-detail/' + item.slug}><i className="fa fa-check" aria-hidden="true"></i> {item.name}</Link></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } 
        </div>
    );
}

export default FAQsList;
