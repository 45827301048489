import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Badge, Spin, Modal } from 'antd';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import logo from '../../../assets/images/shopsocks5.png';
import { config, notification } from '../../../endpoint';

const Header = () => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const [settingMenu, setSettingMenu] = useState({});
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [type, setType] = useState('all');
    const [hasMore, setHasMore] = useState(true);
    const [hasUserView, setHasUserView] = useState(false);
    const [changeTabLoading, setChangeTabLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [current, setCurrent] = useState([]);

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push({pathname: '/login.html'});
    }

    const nl2br = (str, is_xhtml = null) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || is_xhtml == null || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    const formatField = (num) => {
        var numx = (num < 10)? "0" + num : num;
        return numx;
    }

    const timeNow = () => {
        var d = new Date((new Date).toLocaleString('en-US', {
            timeZone: 'Asia/Ho_Chi_Minh'
        }));
        var day = d.getDate();
        var month = d.getMonth();
        var year = d.getFullYear();
        var hour = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();
        var innerHTML = (hour) + ":" + (minutes) + ":" + formatField(seconds) + " (" + day + "/" + (formatField(month+1)) + "/" + year + ")";
        $("#timecontainer").html(innerHTML);
    }

    const activeRoute = (routeName) => {
        const location = useLocation();
        return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    const onClick = () =>{
        $('#navbarColor03').toggleClass('show');
    }

    const getNotification = (typeNotification = '', pageSize = 0) => {
        if (typeNotification == '') {
            typeNotification = type;
        }
        if (pageSize == 0) {
            pageSize = page;
        }
        notification.getListWebsite({type: typeNotification, current: pageSize, pageSize: 5}).then(res => {
            if (res.status == 'success') {
                if (page * 5 >= res.total) {
                    setHasMore(false);
                }
                if (pageSize == 1) {
                    setData(res.response);
                } else {
                    setData(data.concat(res.response));
                }
                setCount(res.not_ready);
                setTotal(res.total);
                setPage(page + 1);
                setHasUserView(res.view_is_all);
            }
        }).then(comp => {
            setChangeTabLoading(false);
        }).then(error => {
            setChangeTabLoading(false);
        });
    }

    const changeTab = (typeTab) => {
        if (typeTab != type) {
            setChangeTabLoading(true);
            setType(typeTab);
            setHasMore(true);
            setData([]);
            setPage(1);
            getNotification(typeTab, 1);
        }
    }

    const ChangeAll = () => {
        notification.changeAllStatusWebsite({'status': 1}).then(res => {
            if (res.status == 'success') {
                $('.message-item').removeClass('not-ready');
                setCount(0);
            }
        }).then(comp => {
        }).then(error => {
        });
    }

    const showModal = (item) => {
        setCurrent(item);
        setIsModalVisible(true);

        notification.changeStatusWebsite(item?.id, {'status': 1}).then(res => {
            if (res.status == 'success') {
                $('#message-item'+ item?.id).removeClass('not-ready');
                if(count - 1 >= 0){
                    setCount(count - 1);
                }
            }
        }).then(comp => {
        }).then(error => {
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setInterval(timeNow, 1000);
        config.getSettingMenu().then(res => {
            if (res.status == 'success') {
                if (res.response != null) {
                    setSettingMenu(res.response);
                }
            }
        });
        if (user != null) {
            getNotification();
        }
    }, []);

    return (
        <header className="header">
            <nav className="container navbar navbar-expand-lg navbar-light navbar-default">
                <Link className="navbar-brand" to="/">
                    <img src={logo} />
                </Link>
                <div className="collapse navbar-collapse" id="navbarColor03">
                    <ul className="navbar-nav ml-auto menu-right menu-mobile">
                        <li className={activeRoute('/home')}>
                            <Link to="/home">Home</Link>
                        </li>
                        <li className={activeRoute('buy-socks5.html') + (settingMenu?.buy_socks5 == 1 ? ' new' : '')}>
                            <Link to="/buy-socks5.html">Buy Socks5</Link>
                        </li>
                        <li className={activeRoute('socks5-premium.html') + (settingMenu?.premium_socks5 == 1 ? ' new' : '')}>
                            <Link to="/socks5-premium.html">Premium Socks5</Link>
                        </li>
                        <li className={activeRoute('view-sock.html') + (settingMenu?.socks5_daily == 1 ? ' new' : '')}>
                            <Link to="/view-sock.html">Socks5 Daily</Link>
                        </li>
                        <li className={activeRoute('socks5-list.html') + (settingMenu?.socks5_list == 1 ? ' new' : '')}>
                            <Link to="/socks5-list.html">Socks5 List</Link>
                        </li>
                        <li className={activeRoute('socks5-24h.html') + (settingMenu?.socks5_24h == 1 ? ' new' : '')}>
                            <Link to="/socks5-24h.html">Socks5 24H</Link>
                        </li>
                        {/* <li className={activeRoute('checksocks5') + (settingMenu?.check_socks5 == 1 ? ' new' : '')}>
                            <Link to="/checksocks5">Check Socks5</Link>
                        </li> */}
                        <li className={(settingMenu?.api == 1 ? ' new' : '')}>
                            <a href="/faq-detail/api">API</a>
                        </li>
                        <li className={(settingMenu?.soft_client == 1 ? ' new' : '')}>
                            <a href="/faq-detail/how-to-use-shopsocks5-socks5-client">Soft-Client</a>
                        </li>
                        <li className={activeRoute('login.html') + (user != null ? 'd-none' : '')}>
                            <Link to="/login.html">Login</Link>
                        </li>
                        <li className={activeRoute('register.html') + (user != null ? 'd-none' : '')}>
                            <Link to="/register.html">Register</Link>
                        </li>
                        <li className={activeRoute('my-account.html') + (user != null ? '' : 'd-none') + (settingMenu?.my_account == 1 ? ' new' : '')}>
                            <Link to="/my-account.html">My account</Link>
                        </li>
                        <li className={activeRoute('contacts') + (settingMenu?.contacts == 1 ? ' new' : '')}>
                            <Link to="/contacts.html">Contact</Link>
                        </li>
                        <li className={(user != null ? '' : 'd-none')}>
                            <a onClick={logout}>Logout</a>
                        </li>
                    </ul>
                </div>
                <div className={"notifications " + (user != null ? '' : 'd-none')}>
                    <ul className='pl-0 mb-0'>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                                {
                                    count > 0 ? (<Badge count={count} className="text-white"><i className="fas fa-bell font-18 text-white" aria-hidden="true"></i></Badge>) : <i className="fas fa-bell font-18 text-white" aria-hidden="true"></i>
                                }
                            </DropdownToggle>
                            <DropdownMenu right className="mailbox pt-0">
                                <span className="with-arrow"><span className="bg-white" style={{backgroundColor: "#fff"}}></span></span>
                                <div className="message-center notifications">
                                    {   count > 0 && (<div className="view-all text-center">
                                            <a onClick={ChangeAll}>View All</a>
                                        </div>)
                                    }
                                    <ul className='tab-notification d-flex pl-0 bg-yellow'>
                                        <li className={type == 'all' ? ' active' : ''}>
                                            <a onClick={() => changeTab('all')}><i className="fa fa-globe" aria-hidden="true"></i> All</a>
                                        </li>
                                        <li className={type == 'alert' ? ' active' : ''}>
                                            <a onClick={() => changeTab('alert')}><i className="fa fa-comment" aria-hidden="true"></i> Website</a>
                                        </li>
                                        <li className={type == 'order' ? ' active' : ''}>
                                            <a onClick={() => changeTab('order')}><i className="fa fa-shopping-bag" aria-hidden="true"></i> Order</a>
                                        </li>
                                        <li className={type == 'reset' ? ' active' : ''}>
                                            <a onClick={() => changeTab('reset')}><i className="fa fa-reply-all" aria-hidden="true"></i> Reset</a>
                                        </li>
                                        <li className={type == 'convert' ? ' active' : ''}>
                                            <a onClick={() => changeTab('convert')}><i className="fa fa-compress" aria-hidden="true"></i> Convert</a>
                                        </li>
                                        <li className={type == 'contact' ? ' active' : ''}>
                                            <a onClick={() => changeTab('contact')}><i className="fa fa-question-circle" aria-hidden="true"></i> Contact</a>
                                        </li>
                                    </ul>
                                    <InfiniteScroll
                                        dataLength={data.length}
                                        next={getNotification}
                                        hasMore={hasMore}
                                        height={300}
                                        loader={<div className="text-center"><Spin size="small" /></div>}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {
                                            data && data.length > 0 && data.map((item, key) => 
                                                <span onClick={() => showModal(item)} id={"message-item" + item.id} className={"message-item" + (((item.is_ready == 0 && item.is_all == 0) || (!hasUserView && item.is_all == 1 && item.is_check_datetime)) ? " not-ready" : "")} key={item.id}>
                                                    <span className="btn btn-circle btn-primary">
                                                        <i className="fa fa-bell"></i>
                                                    </span>
                                                    <div className="mail-contnet">
                                                        <h5 className="message-title">{item.title}</h5>
                                                        <span className="time">{item.date}</span>
                                                    </div>
                                                </span>
                                            )
                                        }
                                    </InfiniteScroll>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ul>
                </div>
                <button className="navbar-toggler" type="button" onClick={onClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
            <div className="header-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="pull-left remove-margin"><b>Current Time: <span className="date-time-client text-black" id="timecontainer">21:30:21 (10/09/2021)</span></b></p>
                            <div className="pull-right">
                                <Link to="/faqs.html"><b><i className='fa fa-question-circle'></i> Instructions</b></Link>
                                <a href="/faq-detail/terms-and-conditions-and-privacy-policy" className='pl-3'><b>Terms</b></a>
                                <a href="/faq-detail/faq" className='pl-3'><b>FAQs</b></a>
                            </div>
                            {/* <div className="pull-right language d-none"><span>Languages: <span className="active">English or Vietnamese</span></span></div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Notification" visible={isModalVisible} footer={false} onCancel={handleCancel}>
                <h4>{current?.title}</h4>
                <div className="mt-2" dangerouslySetInnerHTML={{__html: nl2br(current?.description) }} />
                {
                    current?.type == 'new_order' ? (
                        <p><b>You can see all order at: <a href="/orders.html">Orders History</a></b></p>
                    ) : ""
                }
                {
                    current?.type == 'reset_plan' || current?.type == 'reset_plan_free' ? (
                        <p><b>You can see all reset limit at: <a href="/reset.html">Reset History</a></b></p>
                    ) : ""
                }
                <div className="mt-2 text-right"><b>{current?.date}</b></div>
            </Modal>
        </header>
    );
}

export default Header;