import { Request } from '../_until';
import { notification } from 'antd';

export const dashboard = {
    getList
};

const urls = {
    get_list: `/chart/info`,
};

async function getList() {
    const res = await Request.get(urls.get_list, {});
    try {
        return res.data;
    } catch (e) {
    }
    return null;
};