import React, { useState, useEffect } from 'react';
import { Tabs, Tooltip, Spin, Modal, Form, notification, Button, Input, message } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { plan, payment, banner, account, authenticator } from '../../endpoint';
import { EnvironmentOutlined } from '@ant-design/icons';


import perfectMoney from '../../assets/images/payment/perfect-money.png';
import webMoney from '../../assets/images/payment/webmoney.png';
import bitCoin from '../../assets/images/payment/bitcoin.png';
import bitcoinCash from '../../assets/images/payment/bitcoincash.png';
import tether from '../../assets/images/payment/tether.png';
import usdt from '../../assets/images/payment/usdt.png';
import bep20 from '../../assets/images/payment/bep20.png';

import qrc_bitCoin from '../../assets/theme/images/qr-btc.png';
import qrc_bitcoinCash from '../../assets/theme/images/qr-bitcoin-cash.png';

const { TabPane } = Tabs;

const Buysock = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [isModalVisiblePlan, setIsModalVisiblePlan] = useState(false);
    const [dataListBanner, setListBanner] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [data, setData] = useState([]);
    const [dataPayment, setDataPayment] = useState([]);
    const [note, setNote] = useState("");
    const [planChoose, setPlanChoose] = useState({});
    const [typePlan, setTypePlan] = useState("");
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const [form] = Form.useForm();
    const action = "payment_return";
    const token = "Abc@123";
    const ___action = "return_payment_abc123";

    const showModal = (plan, type) => {
        form.resetFields();
        if (type == 'bitcoin') {
            setDataPayment({
                plan: plan,
                type: type,
                plan_price: plan.plan_price,
                payment_name: "Bitcoin (BTC)",
                payment_address: "14LUfvDXQBTJWSNMyagrVHjfyo1c6sLBCV",
                image: "https://shopsocks5.com/assets/qr-btc.png"
            });
            setIsModalVisible(true);
        } else if(type == 'bitcoincash') {
            setDataPayment({
                plan: plan,
                type: type,
                plan_price: parseFloat(plan.plan_price),
                payment_name: "Bitcoin Cash (BCH)",
                payment_address: "14LUfvDXQBTJWSNMyagrVHjfyo1c6sLBCV",
                image: "https://shopsocks5.com/assets/qr-bitcoin-cash.png"
            });
            setIsModalVisible(true);
        } else if(type == 'erc20') {
            setDataPayment({
                plan: plan,
                type: type,
                plan_price: parseFloat(plan.plan_price),
                payment_name: "USDT (ERC20)",
                payment_address: "0x4e9b6dc9d1717b6aed27aad520a00afadbddace2",
                image: "https://shopsocks5.com/assets/qr-erc20.png"
            });
            setIsModalVisible(true);
        } else if(type == 'trc20') {
            setDataPayment({
                plan: plan,
                type: type,
                plan_price: parseFloat(plan.plan_price),
                payment_name: "USDT (TRC20)",
                payment_address: "TNaBJknjJyyWAaa7rUcWUXzRVzxpppC5dW",
                image: "https://shopsocks5.com/assets/qr-trc20.png"
            });
            setIsModalVisible(true);
        } else if(type == 'bep20') {
            setDataPayment({
                plan: plan,
                type: type,
                plan_price: parseFloat(plan.plan_price),
                payment_name: "USDT BSC (BNB Smart Chain(BEP20))",
                payment_address: "0x4e9b6dc9d1717b6aed27aad520a00afadbddace2",
                image: "https://shopsocks5.com/assets/qr-bep20.png"
            });
            setIsModalVisible(true);
        } else {
            getConfirm({
                plan: plan,
                type: type,
                plan_price: plan.plan_price,
            });
        }
    }

    const handleBuyAnother = () => {
        setIsModalVisiblePlan(false);
        showModal(planChoose, typePlan);
    }

    const showModalPlan = (plan, type) => {
        if (profile?.plan_type != "None" && !(profile?.plan_type == plan.plan_type && profile?.plan_daily_limit == plan.plan_daily_limit)) {
            setTypePlan(type);
            setPlanChoose(plan);
            setIsModalVisiblePlan(true);
        } else {
            showModal(plan, type);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
        setIsModalVisiblePlan(false);
    };

    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    const onFinish = (values) => {
        if(dataPayment?.plan){
            const hide = message.loading('Processing...');
            setLoadingForm(true);
            payment.config_payment({...values, plan_id: dataPayment?.plan.plan_id, type: dataPayment?.type}).then(res => {
                if (res.status == 'success') {
                    // notification.open({
                    //     message: 'Success',
                    //     description: 'Confirm successfully. Your account will be activated within 12 hours',
                    //     type: 'success'
                    // });
                    if (res.new_order == true) {
                        setIsModalVisible(false);
                        history.push({pathname: '/success.html'});
                    } else {
                        localStorage.setItem('confirm_id', res.confirm_id);
                        localStorage.setItem('duration', 60 * 15);
                        setIsModalVisible(false);
                        history.push({pathname: '/pendding.html'});
                    }
                } else if(res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
                //setLoadingForm(false);
            }).then(err => {
                console.log(err);
                hide();
                //setLoadingForm(false);
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please choose plan!',
                type: 'error'
            });
        }
    }

    const getConfirm = (data) => {
        if (data) {
            const hide = message.loading('Processing...');
            payment.get_confirm({plan_id: data.plan.plan_id, 'type': data.type}).then(res => {
                if (res.status == 'success') {
                    const response = res.response;
                    if (response.plan_price == data.plan.plan_price) {
                        var submit = document.createElement('form');
                        submit.method = "POST";
                        var is_submit = false;
                        if (data.type == "perfect-money") {
                            is_submit = false;
                            $('#div_element').html($.base64.atob(res.code, true));
                        } else if(data.type == "webMoney") {
                            is_submit = false;
                            $('#div_element').html($.base64.atob(res.code, true));
                        }
                        if (is_submit) {
                            // document.body.appendChild(submit);
                            // submit.submit();
                        }
                    }
                } else if(res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
            }).then(err => {
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please choose plan!',
                type: 'error'
            });
        }
    }

    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        document.title = "Buy Socks5 - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    plan.getListPlan().then(response => {
                        if (response.status == 'success') {
                            setData(response.response);
                            setLoading(false);
                        }
                    });

                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                        }
                    });

                    banner.get_list_banner({position: 'buy_sock'}).then(res => {
                        if (res.status == 'success') {
                            setListBanner(res.response);
                        }
                    });
        
                    account.note().then(res => {
                        if (res.status == 'success' && res.response) {
                            setNote(nl2br(res.response.config_description));
                        }
                    });
                }
            }).then(res => {
            });
        }
    }, []);


    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    function paymentList(record) {
        return (
            <ul className="list-unstyled remove-margin list-payment">
                <li className={window.confirm.is_payment_pm != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Perfect Money">
                        <a className="payment" onClick={() => showModalPlan(record, "perfect-money")}><img src={perfectMoney} /> </a>
                    </Tooltip>
                </li>
                <li className={(window.confirm.is_payment_wmz != "1" || record.plan_price > 190) ? 'd-none' : ''}>
                    <Tooltip placement="top" title="WebMoney">
                        <a className="payment" onClick={() => showModalPlan(record, "webMoney")}><img src={webMoney} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_btc != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Bitcoin">
                        <a className="payment" onClick={() => showModalPlan(record, "bitcoin")}><img src={bitCoin} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_bch != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Bitcoin Cash">
                        <a className="payment" onClick={() => showModalPlan(record, "bitcoincash")}><img src={bitcoinCash} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_erc20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT (ERC20)">
                        <a className="payment erc20" onClick={() => showModalPlan(record, "erc20")}><img src={tether} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_trc20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT (TRC20)">
                        <a className="payment trc20" onClick={() => showModalPlan(record, "trc20")}><img src={usdt} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_bep20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT BSC (BNB Smart Chain(BEP20)) ">
                        <a className="payment trc20" onClick={() => showModalPlan(record, "bep20")}><img src={bep20} /></a>
                    </Tooltip>
                </li>
            </ul>
        );
    }

    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full == 1 ? '6' : '6')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                            <div className={"tab-buy-socks nav-pills" + (loading ? " d-none" : "")}>
                                <Tabs>
                                    <TabPane tab="SOCKS5 PREMIUM" key="5">
                                        <div title="PLAN SOCKS5 PREMIUM" className="plan panel panel-default">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 PREMIUM</h3>
                                            <div className="panel-body">
                                                <p className="remove-margin">
                                                    <Link to="/socks5-premium.html?all=1"><b>For example, PREMIUM 200 SOCKS5: </b></Link>
                                                    You will have 60days get socks5. After you get enough 200 socks5 or after 60 days from the date of account activation your account will expired. Numbers of socks5 will get at <Link to="/socks5-premium.html"><b>PREMIUM SOCKS5</b></Link>
                                                </p>
                                                <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-premium">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-premium</Link></p>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                            <thead>
                                                                <tr>
                                                                    <th>Plan</th>
                                                                    <th>Daily Expired</th>
                                                                    <th>Price($)</th>
                                                                    <th>Credit Expired</th>
                                                                    <th>Total Socks5 Receive</th>
                                                                    <th>Payment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.Premium && data?.Premium.length > 0 && data?.Premium.map((item, key) => 
                                                                        <tr key={item.plan_id}>
                                                                            <td data-title="Plan">{item.plan_name}</td>
                                                                            <td data-title="Daily Expired">{item.plan_expired == 0 ? window.confirm.number_day + ' Days' : item.plan_expired + ' Days'}</td>
                                                                            <td data-title="Price($)">{item.plan_price} $</td>
                                                                            <td data-title="Credit Expired">{"Until Credit = 0"}</td>
                                                                            <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                            <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="SOCKS5 DAILY" key="1">
                                        <div title="PLAN SOCKS5 DAILY" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                            <div className="panel-body">
                                                <p className="remove-margin">
                                                    <Link to="/buy-socks5.html"><b>For example, Daily 90 Socks5: </b></Link>
                                                    You can get maximium 90 socks for a day. If you don't use, you will lose 90 socks in a day ( non-cumulative socks5). Numbers of socks5 will get at <Link to="/view-sock.html"><b>SOCKS5 DAILY</b></Link>
                                                    <br/>Plan Daily 90 Socks5 30 days you will maximum: 90*30=2700sock5
                                                </p>
                                                <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-daily">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-daily</Link></p>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Tabs className="tab-day" defaultActiveKey="30day">
                                                        <TabPane tab="15 Days" key="15day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="30 Days" key="30day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="365 Days" key="365day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>       
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="PLAN SOCKS5 LIST" key="3">
                                        <div title="PLAN SOCKS5 LIST" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                            <div className="panel-body">
                                                <p className="remove-margin">
                                                    <Link to="/buy-socks5.html"><b>For example, Plan List 200 Socks5: </b></Link>
                                                    You will have 200 socks5 per day ( non-cumulative socks5). Numbers of socks5 will get at <Link to="/socks5-list.html"><b>SOCKS5 LIST</b></Link>
                                                    <br/>Plan LIST 200 Socks5 30 days you will have maximum: 200*30 = 6000 Socks5
                                                </p>
                                                <p className="mt-3">How to use: <Link to="/faq-detail/how-to-buy-and-how-to-use-plan-socks5-list">https://shopsocks5.com/faq-detail/how-to-buy-and-how-to-use-plan-socks5-list</Link></p>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Tabs className="tab-day" defaultActiveKey="list-30day">
                                                        <TabPane tab="15 Days" key="list-15day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="30 Days" key="list-30day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="365 Days" key="list-365day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Reset Free</th>
                                                                            <th>Reset(-1)</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Reset Free">{item?.is_reset_free == 1 ? "Yes" : "No" }</td>
                                                                                    <td data-title="Reset(-1)">{item.is_reset == 1 ? "Yes" : "No"}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item) }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>       
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="div_element" className="d-none"></div>
            <Modal title="Make Payment" visible={isModalVisible} onCancel={handleCancel} footer={null} width={760}>
                <h6>Adding <strong style={{color: "red"}}>${dataPayment?.plan_price}</strong> balance to user account: {user?.user_name} in shopsocks5.com</h6>
                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>Step 1: Send payment to</strong></p>
                        <p>Login into your blockchain and Please send <strong style={{color: "red"}}>${dataPayment?.plan_price}</strong> without payment fee (your blockchain)</p>
                        <p>
                            {dataPayment?.payment_name} Address:<br/>
                            <b>{dataPayment?.payment_address}</b><br/><br/>
                            {dataPayment?.payment_name} Address QR CODE:<br/>
                            <img src={dataPayment?.image} width="180px" />
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Step 2: Confirm payment</strong></p>
                        <p>After payment is done, please paste your billing address in the box below and press confirm and wait some minutes to complete!</p>
                        <p className="mb-0"><b>Paste Your TX (Hash) payment:</b></p>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            className="form-horizontal mt-3 pd-2"
                        >
                            <div className="row">
                                <div className="col-sm-7">
                                    <Form.Item
                                        label=""
                                        labelCol={{span: 0}}
                                        name="address"
                                        rules={[{ required: true, message: 'Please input your address!' }]}
                                    >
                                        <Input prefix={<EnvironmentOutlined className="site-form-item-icon" />} placeholder="Address!" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-5">
                                    <Button type="primary" htmlType="submit" loading={loadingForm} className="d-block medium">Click confirm</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                { dataPayment?.type == 'bitcoin' && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-bitcoin-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-bitcoin-wallet</Link></p>}
                { dataPayment?.type == 'bitcoincash' && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-bitcoincash-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-bitcoincash-wallet</Link></p>}
                { dataPayment?.type == 'erc20' && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdterc20-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdterc20-wallet</Link></p>}
                { dataPayment?.type == 'trc20' && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdttrc20-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdttrc20-wallet</Link></p>}
                { dataPayment?.type == 'bep20' && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdtbsc-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdtbsc-wallet</Link></p>}
                <hr />
                <p className="text-center">
                    <b>After payment your account not working<br/> 
                    Please Contact Skype: {window.confirm?.skype} <br/> 
                    Thank you buy socks at Shopsocks5.com</b>
                </p>
            </Modal>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
            <Modal visible={isModalVisiblePlan} footer={null} closable={false} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <div className='alert alert-warning'>WARNING!</div>
                        <p>You're current using plan ({profile?.plan_current_name}), so you only renew plan ({planChoose?.plan_name}), can't upgrade new plan.</p>
                        <p>If you buy another plan , Old plan will be canceled please waiting for the current plan to expire.</p>
                        <p>If you are sure you want to buy another package, please click buy another package</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleBuyAnother} className="d-block medium">Buy another package</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={handleCancel} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Buysock;