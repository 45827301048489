import React from 'react';
import { Modal, Form, Button } from 'antd';
import { ProFormSelect, ProFormText, ProFormDateTimePicker, ProFormDigit, StepsForm } from '@ant-design/pro-form';
import { plan } from '../../../../endpoint';

const UserUpdate = (props) => {

    const timeFormat = "DD/MM/YYYY HH:mm";

    const plans = async () => {
        const data = await plan.getSelect();
        return data;
    }

    return (
        <Modal
            width={420}
            bodyStyle={{
                padding: '32px 40px 48px',
            }}
            destroyOnClose
            footer={false}
            title="Edit User"
            visible={props.updateModalVisible}
            onCancel={() => {
                props.onCancel();
            }}
        >
            <Form
                onFinish={props.onSubmit}
                initialValues={{
                    user_name: props.values?.user_name,
                    user_mail: props.values?.user_mail,
                    number_socks: props.values?.number_socks,
                    user_exdate: props.values?.user_exdate,
                    plan: {value: props.values?.plan, label: props.values?.plan_name},
                    user_groupid: {value: props.values?.user_groupid, label: props.values?.type_name}
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Username is required"
                        }
                    ]}
                    width="lg"
                    name="user_name"
                    label="User Name:"
                    placeholder="User Name"
                    disabled={true}
                />
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Email address is required"
                        },
                        {
                            type: "email",
                            message: "The input is not valid E-mail!"
                        }
                    ]}
                    width="lg"
                    name="user_mail"
                    label="Email address:"
                    type="email"
                    placeholder="Email address"
                    disabled={true}
                />
                <ProFormSelect
                    width="lg"
                    name="user_groupid"
                    label="User Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Administrator', value: '1'},
                        { label: 'V.I.P', value: '2' },
                        { label: 'Member', value: '3' }
                    ]}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your type!' 
                        }
                    ]}
                />
                <ProFormSelect
                    showSearch
                    width="lg"
                    name="plan"
                    label="Plan"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={plans}
                    placeholder="Please select a plan"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your plan!' 
                        }
                    ]}
                />
                <ProFormText.Password
                    rules={[
                        {
                            min: 6,
                            message: "Password minlength 6 charaters"
                        }
                    ]}
                    width="lg"
                    name="user_pass"
                    placeholder="Password"
                    label="Password:"
                />
                <ProFormDigit 
                    label="Sock Number" 
                    name="number_socks" 
                    width="lg" 
                    placeholder="Sock Number"
                    min={0}
                />
                <ProFormDateTimePicker 
                    name="datetime" 
                    label="Expired: " 
                    width="lg"
                    name="user_exdate"
                    placeholder="Expired"
                    fieldProps={{
                        showTime: { format: timeFormat },
                        format: [timeFormat]
                    }}
                />
                <div className="text-right">
                    <Button type="primary" htmlType="submit">Update</Button>
                </div>
            </Form>
        </Modal>
    );
};
export default UserUpdate;
