import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, Link  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, AutoComplete } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { ticket, users, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = AutoComplete;

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.addItem({ ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
*/
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await ticket.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.changeStatus(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const TicketListComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [dataEditor, setDataEditor] = useState("");
    const actionRef = useRef(null);
    const [value, setValue] = useState("");
    const [userID, setUserID] = useState(0);
    const [options, setOptions] = useState([]);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await ticket.getList(parameters);
        return data;
    }

    const getUsers = async (parameters) => {
        const data = await users.getList(parameters);
        return data != null ? data : {};
    }

    const onSearch = async (searchText) => {
        const users = await getUsers({"user_name": searchText});
        setOptions(users.data);
    };

    const onSelect = (data, record) => {
        setUserID(record.key);
        setValue(data);
    };

    const onChange = (user_name, record) => {
        setUserID(record.key);
        setValue(user_name);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: 'title',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
            render: (dom, entity) => {
                return (
                    <Link to={'/ntsock/contact-detail/' + entity.id} className="text-success"><span className="line-bottom">{dom}</span></Link>
                );
            }
        },
        {
            title:'User Name',
            dataIndex: 'user_name'
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Close',
                    status: 'red',
                },
                1: {
                    text: "Open",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Open" unCheckedChildren="Close" onChange={(value) => handledChangeStatus(value, record)} key={record.id} defaultChecked={record?.status  == 1 ? true : false} />
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <Link key="view" to={'/ntsock/contact-detail/' + record.id} className="text-success"><i className='fa fa-eye'></i> View Detail</Link>
                ,
                <a key="edit"
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        handleModalVisible(true);
                        setEditorLoaded(true);
                        setDataEditor(record.content);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete"><DeleteOutlined />  Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Contact Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                            setEditorLoaded(true);
                            setDataEditor("");
                            setUserID(null);
                            setCurrentRow({user_id: '0', title: '', content: '', status: 1});
                            form.resetFields();
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-faq"
                title="FAQ Form"
                width="768px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Title is required"
                        },
                    ]}
                    initialValue={currentRow?.title}
                    width="full"
                    name="title"
                    label="Title:"
                />
                {
                    editorLoaded ? (
                        <div className="mb-4">
                            <label>Content</label>
                            <CKEditor
                                editor={ClassicEditor}
                                id="content"
                                config={{
                                    ckfinder: {
                                        //uploadUrl: faqs.getUploadUrl()
                                    }
                                }}
                                data={dataEditor}
                                    onReady={(editor) => {
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDataEditor(data);
                                }}
                                
                            />
                        </div>
                    ) : ""
                }

                <div className="ant-form-item-label">
                    <label>User</label>
                </div>
                <AutoComplete
                    style={{ width: "calc(100% - 14px)", "maxWidth": '100%', "marginBottom": 15}}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onChange={onChange}
                >
                    {options && options.map((record) => (
                        <Option key={record.user_id} value={record.user_name} label={record.user_name}>
                            {record.user_name}
                        </Option>
                    ))}
                </AutoComplete>

                <ProFormSelect
                    name="status"
                    label="Status"
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Open', value: 1},
                        { label: 'Close', value: 0 }
                    ]}
                    initialValue={currentRow?.status}
                    placeholder="Status"
                />

                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        onClick={async () => {
                            const value = form.getFieldsValue();
                            form.submit();
                            if (value.title && dataEditor) {
                                if (currentRow?.id) {
                                    const success = await handleUpdate({...value, 'content': dataEditor}, currentRow?.id);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                } else {
                                    if (userID != null) {
                                        const success = await handleAdd({...value, 'user_id': userID, 'content': dataEditor});
                                        if (success) {
                                            form.resetFields();
                                            handleModalVisible(false);
                                            if (actionRef.current) {
                                                actionRef.current.reload();
                                            }
                                        }
                                    } else {
                                        notification.open({
                                            message: 'Fail!',
                                            description: "Please Choose user",
                                            type: 'error'
                                        });
                                    }
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default TicketListComponent;
