import { Request } from '../_until';

export const payment = {
    get_confirm,
    config_payment,
    check_payment
};

const urls = {
    get_confirm: `payment/get_confirm`,
    config_payment: `payment/config_payment`,
    check_payment: `payment/check_confirm`
};

function get_confirm(requestOptions) {
    return Request.post_website(urls.get_confirm, requestOptions).then(res => res.data);
};

function config_payment(requestOptions) {
    return Request.post_website(urls.config_payment, requestOptions).then(res => res.data);
};

function check_payment(requestOptions) {
    return Request.post_website(urls.check_payment, requestOptions).then(res => res.data);
};