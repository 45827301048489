import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { auth } from '../../../../endpoint';
import '../../../../assets/scss/custom-admin.scss';
import '../../../../assets/scss/all/icons/themify-icons/themify-icons.css';
import background from "../../../../assets/images/auth-bg.jpg";
import logo from "../../../../assets/images/online.png";

const Login = () => {

    const history  = useHistory();

    const onFinish = async (values) => {
        //console.log('Success:', values);
        const hide = message.loading('Processing...');
        try {
            var res = await auth.login(values);
            hide();
            if (res.status == 'success') {
                const user = res.response;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', user.token);
                notification.open({
                    message: 'Success',
                    description: 'Login successfully',
                    type: 'success'
                });
                history.go({pathname: '/ntsock/dashboard'});
            }
            else{
                if(typeof res.message != 'undefined'){
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } 
        catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Login failed, please try again!',
                type: 'error'
            });
        }
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if(localStorage.getItem('user') != null){
            history.push({pathname: '/ntsock/dashboard'});
        }
    }, []);

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={{"background": "no-repeat center center", backgroundImage: `url(${background})`, "backgroundColor": "rgb(238, 245, 249)"}}>
            <div className="auth-box">
                <div id="loginform">
                    <div className="logo">
                        <span className="db">
                            <img src={logo} alt="logo" width="40px" />
                        </span>
                        <h5 className="font-medium mb-3 mt-2">Sign In to Admin</h5>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                className="form-horizontal mt-3"
                                id="loginform"
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                                </Form.Item>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <div className="d-flex align-items-center">
                                            <Form.Item name="remember" valuePropName="checked" className="mb-0">
                                                <Checkbox>Remember me</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 text-center">
                                    <div className="col-xs-12 pb-3">
                                        <Button type="primary" htmlType="submit" className="d-block medium">Log In</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
