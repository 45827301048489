import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Input,
    Table
} from 'reactstrap';
import { Spin } from 'antd';
import { users } from '../../../../../endpoint';

const Projects = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        list();
    }, []);

    const list = async () => {
        const data = await users.getList({current: 1, pageSize: 5});
        if(data.success){
            setData(data.data);
        }
        setLoading(false);
    }

    return (
        <Card>
            <CardBody>
                <div className="d-flex align-items-center">
                    <div>
                        <CardTitle>New Users</CardTitle>
                        <CardSubtitle>Overview of Latest</CardSubtitle>
                    </div>
                    <div className="ml-auto d-flex no-block align-items-center">
                        <div className="dl d-none">
                            <Input type="select" className="custom-select">
                                <option value="0">Monthly</option>
                                <option value="1">Daily</option>
                                <option value="2">Weekly</option>
                                <option value="3">Yearly</option>
                            </Input>
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (

                        <Table className="no-wrap v-middle" responsive>
                            <thead>
                                <tr className="border-0">
                                    <th className="border-0">#</th>
                                    <th className="border-0">Username</th>
                                    <th className="border-0">Email</th>
                                    <th className="border-0">Status</th>
                                    <th className="border-0">Group</th>
                                    <th className="border-0">Time Login</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 && data.map((item, key) => 
                                        <tr key={item.user_id}>
                                            <td>{key}</td>
                                            <td>
                                                <div className="d-flex no-block align-items-center">
                                                    <div className="mr-2"><img src={item?.avatar} alt="user" className="rounded-circle" width="45" /></div>
                                                    <div className="">
                                                        <h5 className="mb-0 font-16 font-medium">{item.user_name}</h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{item.user_mail}</td>
                                            <td>
                                                {
                                                    item.user_status == 1 ? 
                                                    (
                                                        <i className="fa fa-circle text-success" id="tlp2"></i>
                                                    ) : 
                                                    (
                                                        <i className="fa fa-circle text-orange" id="tlp1"></i>
                                                    )
                                                }
                                            </td>
                                            <td>{item.type_name}</td>
                                            <td>{item.user_regdate}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    )
                }
            </CardBody>
        </Card>
    );
}

export default Projects;
