import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

const Tool = () => {
    const history = useHistory();

    useEffect(() => {
        document.title = "How to use shopsocks5 - " + window?.title;
    }, []);

    return (
        <div className="container">
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content p-5">
                            <h2 className="text-center mb-5">HOW TO USE SHOPSOCKS5</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Socks5 Daily</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/5oiw_NKnB7A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-md-6">
                                    <h3>Socks5 Credit</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/FpCiuorBFDc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Socks5 List</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/utDF0fJLiPg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-md-6">
                                    <h3>Socks5 All</h3>
                                    <iframe width="100%" height="315" style={{maxWidth: "100%", marginBottom: "20px", width: "100%"}} src="https://www.youtube.com/embed/mzZlXRyYL80" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <h3 className="mt-3">Contact Us:</h3>
                            <ul>
                                <li><strong>Email:</strong> {window.confirm?.support_email}</li>
                                <li><strong>Skype:</strong> {window.confirm?.skype}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tool;