import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Upload } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDateTimePicker, ProFormDigit } from '@ant-design/pro-form';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ProDescriptions from '@ant-design/pro-descriptions';
import { banner, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';
import BannerUpdate from './bannerUpdate';

/**
 * Update node
 *
 * @param fields
 * @param id
*/
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await banner.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 *  Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await banner.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param val
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await banner.changeStatus(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Sort
 *
 * @param val
 */
const handledUpdateSort = async (data, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await banner.changeSort(id, {'sort': data.sort});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Popover Item
 */
const PopoverItem = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [value, setValue] = useState(props.text);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <div>
            <span className="popover-edit" id={`Popover-${props.id}`} onClick={toggle.bind(null)}>{value} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverOpen} target={`Popover-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Sort
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ sort: value }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateSort(data, props.id)){
                                    setPopoverOpen(false);
                                    setValue(data.sort);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Sort is required"
                                },
                            ]}
                            label="Sort" 
                            name="sort" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

const BannerComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [updateModalVisible, handleUpdateModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());

        const data = await banner.getList(parameters);
        return data;
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    /**
     * Add node
     * @param fields
     */
    const handleAdd = async (fields) => {
        if(file == null){
            notification.open({
                message: 'Fail!',
                description: "Please select image upload!",
                type: 'error'
            });
            return false;
        }
        
        const hide = message.loading('Processing...');
        try {
            const formData = new FormData(); 
            formData.append( 
                "file", 
                file, 
                file.name 
            );
            fields.position = fields.position.value;
            fields.is_full = fields.is_full.value;
            Object.keys(fields).map((key, index) => {
                formData.append(key, fields[key]);
            });

            var res = await banner.addItem(formData);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Added successfully',
                    type: 'success'
                });
                form.resetFields();
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Adding failed, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    const onChange = (file) => {
        setFile(file);
    }

    const onRemove = () => {
        setFile(null);
    }

    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.plan_name,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            }
        },
        {
            title: "Image",
            dataIndex: 'path',
            hideInSearch: true,
            render: (_, record) => [
                <img src={record.path} style={{height: "40px", width: "auto"}} />
            ]
        },
        {
            title:'Url',
            dataIndex: 'url',
            hideInSearch: true,
        },
        {
            title:'Position',
            dataIndex: 'position',
            initialValue: paramQuery?.position,
            valueEnum: {
                "banner_top": {
                    text:'Home',
                },
                "banner_sidebar": {
                    text: "Sidebar"
                },
                "sock": {
                    text: "Sock Page"
                },
                "buy_sock": {
                    text: "Buy Sock"
                }
            },
        },
        {
            title:'Status',
            dataIndex: 'status',
            valueType: 'select',
            initialValue: paramQuery?.status,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
        },
        {
            title:'Full',
            dataIndex: 'is_full',
            hideInSearch: true,
        },
        {
            title: "Sort",
            dataIndex: 'sort',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverItem key={"sort" + record.id} id={record.id} text={record.sort} />
            ]
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="edit"
                    onClick={() => {
                        setCurrentRow(record);
                        handleUpdateModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete"><DeleteOutlined />  Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Banner Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 10)
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-add-banner"
                title="Add new Banner"
                width="500px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    const success = await handleAdd(value);
                    if (success) {
                        handleModalVisible(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="lg"
                    name="name"
                    label="Name:"
                />
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Url is required"
                        },
                    ]}
                    width="lg"
                    name="url"
                    label="Url:"
                />

                <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error mb-4">
                    <div className="ant-col ant-form-item-label">
                        <label for="url" className="ant-form-item-required" title="Url:">Url:</label>
                    </div>
                    <Upload accept="image/*" customRequest={false} beforeUpload={onChange} onRemove={onRemove}>
                        <Button icon={<UploadOutlined />}> Select Image</Button>
                    </Upload>
                </div>

                <ProFormSelect
                    width="lg"
                    name="position"
                    label="Position"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={ () => [
                        { label: 'Home', value: 'banner_top'},
                        { label: 'Sidebar', value: 'banner_sidebar'},
                        { label: 'Buy Sock', value: 'buy_sock'},
                        { label: 'Sock Page', value: 'sock'}
                    ]}
                    placeholder="Please select a Position"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your Position!' 
                        }
                    ]}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Sort is required"
                        },
                    ]}
                    label="Sort" 
                    name="sort" 
                    width="lg" 
                    min={0}
                />
                <ProFormSelect
                    width="lg"
                    name="is_full"
                    label="Full"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Yes', value: '1'},
                        { label: 'No', value: '0' }
                    ]}
                    placeholder="Is Full"
                />
                <ProFormSelect
                    width="lg"
                    name="status"
                    label="Status"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Active', value: '1'},
                        { label: 'Deactive', value: '0' }
                    ]}
                    placeholder="Status"
                />
                <ProFormTextArea
                    rules={[
                        {
                            required: true,
                            message: "Description is required"
                        },
                    ]}
                    row="10"
                    width="lg"
                    name="description"
                    label="Description:"
                    placeholder="Description:"
                />
            </ModalForm>

            {/*Form Update*/}
            <BannerUpdate
                onSubmit={async (value) => {
                    const success = await handleUpdate(value, currentRow?.id);
                    if (success) {
                        handleUpdateModalVisible(false);
                        setCurrentRow(undefined);

                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
                onCancel={() => {
                    handleUpdateModalVisible(false);
                    setCurrentRow(undefined);
                }}
                updateModalVisible={updateModalVisible}
                values={currentRow || {}}
            />   
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <div>
                        <ProDescriptions 
                            title={currentRow?.name}
                            request={async () => ({
                                data: currentRow || {},
                            })}
                            params={{
                                id: currentRow?.id,
                            }}
                            columns={columns}
                        />
                        <img src={currentRow?.path}  style={{maxWidt: "100%", marginTop: "20"}} />
                    </div>
                )}
            </Drawer>
        </PageContainer>
    );
}
export default BannerComponent;
